body {
	margin: auto;
	text-align: center;
	background-color: #f2efe4;
	
}
header {
	padding-bottom: 10px;
	border-bottom: thin solid #777;
    font-family: 'Nunito Sans', sans-serif;
}
label {
	padding: 0;
	margin: 0;
}
h1 {
	margin-top: 5px;
	margin-bottom: 15px;
	padding: 0;
    font-family: 'Nunito Sans', sans-serif;
	font-size: 2em;
	font-weight: bold;
}
footer {
	padding: 10px 0px;
	border-top: thin solid #777;
	font-family: 'Nunito Sans', sans-serif;
}
#title {
	font-size: 4em;
	font-weight: bold;
}
#subtitle {
	font-size: 1.5em;
}
.content {
	padding: 20px 0px 40px 0px;
}
#timer {
    font-size: 10em;
    font-weight: bold;
}
button.btn_default {
	padding: 8px 12px;
    margin: 2px 0px;
    font-weight: 700;
	border: 1px solid;
	border-radius: 9999px;
	color: rgba(112,102,75);
	font-family: 'Nunito Sans', sans-serif;
}

#btn_ctrl {
	border: 2px solid #711;
}

#btn_reset {
    background-color: #F99;
	border: 1px solid #711;
}
#ipt_custom {
	padding: 8px 12px;
    width: 75px;
	border-radius: 4px;
	-webkit-border-radius: 4px;
	border: 1px solid;
	text-align: center;
	font-family: 'Nunito Sans', sans-serif;
}
#custom_units {
	padding: 8px 12px;
	border-radius: 4px;
	-webkit-border-radius: 4px;
	border: 1px solid;
	text-align: center;
	font-family: 'Nunito Sans', sans-serif;
}
div.footerbox {
	max-width: 300px;
	margin:	10px 5px;
	display: inline-block;
	vertical-align: top;
    background-color: #EEE;
	padding: 8px 12px;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	border: 1px solid;
}
div.footerbox ol {
	display: inline-block;
	text-align: left;
	padding-left: 15px;
}
#options {
	text-align: left;
}
#options label {
	margin-bottom: 5px;
}
#options select {
	width: 100%;
	margin-bottom: 20px;
}
.credits {
    margin: 2em 0;
    color: #999;
}